class ArrayHelper {
  sortByProperty<T>(
    array: T[],
    propName: keyof T,
    sortAscending: boolean
  ): void {
    array.sort((a, b) => {
      const propA = a[propName] === null ? "" : a[propName];
      const propB = b[propName] === null ? "" : b[propName];

      return propA < propB ? -1 : propA > propB ? 1 : 0;
    });

    if (!sortAscending) {
      array.reverse();
    }
  }
}

export default new ArrayHelper();
