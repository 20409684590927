
import { Options, Vue } from "vue-class-component";
import DetailsHeader from "@/components/DetailsHeader.vue";
import DetailsRow from "@/components/DetailsRow.vue";
import Table from "@/components/Table.vue";
import Tooltip from "@/components/Tooltip.vue";
import Badge from "@/components/Badge.vue";
import PersonDetails from "@/components/PersonDetails.vue";
import DeleteIcon from "@/components/NoteTakerDetails/DeleteIcon.vue";
import CheckboxInput from "@/components/Form/CheckboxInput.vue";
import RadioButtonGroup from "@/components/Form/RadioButtonGroup.vue";
import TextArea from "@/components/Form/TextArea.vue";
import CourseService from "@/services/course-service";
import NoteTakerService from "@/services/notetaker-service";
import LocalStorageService from "@/services/localstorage-service";
import {
  NoteTaker,
  NoteTakerStatus,
  SupportCourseOccurrence,
  SortOrder,
} from "@/interfaces/api";

import { Constants } from "@/constants/constants";
import ArrayHelper from "@/helpers/array-helper";
import { IUserPagingData } from "@/interfaces/IUserPagingData";

@Options({
  components: {
    DeleteIcon,
    CheckboxInput,
    RadioButtonGroup,
    TextArea,
    DetailsHeader,
    DetailsRow,
    Table,
    PersonDetails,
    Badge,
    Tooltip,
  },
  data: () => ({
    /*
     * Holds the id of the note taker and is populated from the route's id parameter when the page loads
     */
    currentNoteTakerId: 0,
    /*
     * Holds the note taker data and is populated by a call to the note taker service when the page loads
     */
    noteTaker: null,
    /*
     * Populates the status radio button group
     */
    statusOptions: Object.keys(NoteTakerStatus),
    /*
     * Populated by text entered into the admin note textarea
     */
    adminNote: "",
    /*
     * Passed as aria-label value to the admin note textarea
     */
    adminNoteAriaLabel: "Administration Note",
    /*
     * The background color for the tooltip when mousing over course occurrence
     */
    tooltipBgColor: "#875E29",
    /**
     * The current sort column
     */
    sortColumn: "courseOccurrenceStart",
    /**
     * If sort order is ascending
     */
    ascending: true,
    /**
     * Holds the length of the list of note-takers administration notes when the page loads
     */
    administrationNotesOnLoad: 0,
  }),
  computed: {
    /**
     * Gets note-takers support course occurrences
     */
    supportCourseRows() {
      const noteTaker: NoteTaker = this.noteTaker;
      const supportCourseOccurrences =
        noteTaker.supportCourseOccurrences as SupportCourseOccurrence[];
      ArrayHelper.sortByProperty<SupportCourseOccurrence>(
        supportCourseOccurrences,
        this.sortColumn,
        this.ascending
      );
      return supportCourseOccurrences;
    },
    /**
     * Hides all unwanted columns
     */
    hiddenSupportCourseColumns() {
      const hidden = [
        "titleEn",
        "titleSv",
        "courseOccurrenceId",
        "courseUid",
        "offerConfirmedDate",
        "deletedByAdmin",
      ];
      return hidden;
    },
    /*
     * Used by the previous/next button logic
     */
    allNoteTakerIds() {
      const filteredNoteTakerIds = LocalStorageService.getItem<number[]>(
        Constants.FilteredNoteTakerIds
      );
      return filteredNoteTakerIds;
    },
    /*
     * The id of the note taker to load when the next button is clicked
     */
    nextNoteTakerId() {
      const currentIdIndex = this.allNoteTakerIds.indexOf(
        this.currentNoteTakerId
      );
      let nextIdIndex = currentIdIndex + 1;
      if (nextIdIndex >= this.allNoteTakerIds.length) {
        nextIdIndex = 0;
      }
      return this.allNoteTakerIds.slice(nextIdIndex, nextIdIndex + 1)[0];
    },
    /*
     * The id of the note taker to load when the previous button is clicked
     */
    previousNoteTakerId() {
      const currentIdIndex = this.allNoteTakerIds.indexOf(
        this.currentNoteTakerId
      );
      let previousIdIndex = currentIdIndex - 1;
      if (previousIdIndex < 0) {
        previousIdIndex = this.allNoteTakerIds.length - 1;
      }
      return this.allNoteTakerIds.slice(
        previousIdIndex,
        previousIdIndex + 1
      )[0];
    },
    /**
     * Returns the course name
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    courseName(dataRow: any): string {
      return this.course
        ? this.$i18n.locale === "sv"
          ? dataRow?.titleSv
          : dataRow?.titleEn
        : "";
    },
    administrationNotesLength(): number {
      if (this.noteTaker) {
        return this.noteTaker.administrationNotes
          ? this.noteTaker.administrationNotes.length
          : 0;
      }
      return 0;
    },
  },
  methods: {
    /**
     * Fetches the note taker with the id from route parameter
     */
    getNoteTaker(): void {
      const localstorageLang = LocalStorageService.getItem<string>("language");
      this.$root.$i18n.locale = localstorageLang;
      if (this.$route.name === "NoteTakerDetails" && this.$route.params.id) {
        this.currentNoteTakerId = parseInt(this.$route.params.id);
        NoteTakerService.getNoteTakerById(this.currentNoteTakerId)
          .then((data: NoteTaker | undefined) => {
            this.noteTaker = data;
            this.administrationNotesOnLoadLength =
              this.noteTaker.administrationNotes.length;
          })
          .catch((e: Error) => {
            console.error(e);
          });
      }
    },
    /*
     * Navigates to a note taker with the specified id
     * Called from the next/previous button click
     */
    navigateToNoteTaker(id: number): void {
      this.$router.push({
        name: "NoteTakerDetails",
        params: { id: id },
      });
    },
    /*
     * Navigates to the note taker overview
     * Called from the overview button click
     */
    navigateToOverview(): void {
      this.$router.push({
        name: "NoteTakerList",
      });
    },
    /*
     * Adjusts the status of the note taker when the 'Registered' checkbox in the checklist is clicked
     */
    onRegisteredChanged(isChecked: boolean): void {
      switch (this.noteTaker.status) {
        case NoteTakerStatus.Incoming:
          if (isChecked) {
            this.noteTaker.status = NoteTakerStatus.Active;
            // TODO: send request for confirmation mail to be sent from backend
          }
          break;
        default:
          if (!isChecked) {
            this.noteTaker.status = NoteTakerStatus.Incoming;
          }
          break;
      }
    },
    /*
     * Adds a timestamped new admin note to the list of admin notes for the user
     */
    onSaveClicked(): void {
      if (this.adminNote) {
        const options = {
          dateStyle: "short",
          timeStyle: "short",
          hour12: false,
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const dateTime = new Intl.DateTimeFormat("sv", options).format(
          new Date()
        );

        this.noteTaker.administrationNotes.push({
          id: 0,
          note: `${dateTime} : ${this.adminNote}`,
        });

        this.adminNote = "";
      }

      this.updateNoteTaker();
    },
    /*
     * Capitalizes the input string
     */
    capitalize(input: string): string {
      return `${input.substring(0, 1).toUpperCase()}${input.substring(1)}`;
    },
    /**
     * Returns a color based on status
     *
     * @param {string} status The status we want a color for
     */
    statusBgColor(status: string): string {
      return CourseService.courseOccurrenceStatusColor(status);
    },
    /**
     * Returns a color based on status
     *
     * @param {string} status The status we want a color for
     */
    statusTextColor(status: string): string {
      return status === "Yellow" ? "black" : "white";
    },
    /**
     * Returns a text based on status
     *
     * @param {string} status The status we want a tooltip text for
     */
    statusText(status: string): string {
      return this.$t(CourseService.courseOccurrenceStatusText(status));
    },
    /**
     * Triggered when the user changes the sort column/order of the course table
     */
    onSortChange(columnName: string, ascending: boolean): void {
      this.sortColumn = columnName;
      this.ascending = ascending;
      LocalStorageService.setItem(
        Constants.NoteTakerSupportCourseTableUserPagingData,
        this.generateUserPagingData()
      );
      this.sortCourseOccurrences();
    },
    /**
     * Triggered when the user changes the sort column/order of the course table
     */
    sortCourseOccurrences(): void {
      ArrayHelper.sortByProperty<SupportCourseOccurrence>(
        this.supportCourseRows,
        this.sortColumn,
        this.ascending
      );
    },
    /**
     * Generates user paging data to be saved to local storage
     */
    generateUserPagingData(): Partial<IUserPagingData> {
      const userPagingData: Partial<IUserPagingData> = {
        sortColumn: this.sortColumn,
        sortOrder: this.ascending ? SortOrder.Asc : SortOrder.Desc,
      };

      return userPagingData;
    },
    /**
     * Gets the user's paging data that has been saved to local storage
     */
    getUsersPagingData(): void {
      const userPagingData = LocalStorageService.getItem<
        Partial<IUserPagingData>
      >(Constants.NoteTakerSupportCourseTableUserPagingData);

      if (userPagingData) {
        this.sortColumn = userPagingData.sortColumn;
        this.ascending = userPagingData.sortOrder === SortOrder.Asc;
      }
    },
    /**
     * Checks if the specified value is unavailable and if so,
     * replaces it with the the unavailable translation for the current locale
     * Also checks if the value is a date and if so, formats it to YYYY-MM-DD
     */
    replaceUnvailableOrFormatDate(value: string): string {
      return CourseService.replaceUnvailableOrFormatDate(
        value,
        this.$t("course-occurrence.unavailable")
      );
    },
    /**
     * Sets the course title to be used as text for tooltip over link to course detail
     */
    getCourseTitle(titleSv: string, titleEn: string): string {
      if (titleSv === Constants.NotAvailable) {
        return CourseService.getCourseTitleTooltipText(
          this.$t("course-occurrence.title"),
          this.$t("course-occurrence.unavailable")
        );
      }

      return this.$i18n.locale === "sv" ? titleSv : titleEn;
    },
    /**
     * Updates the note-taker
     */
    updateNoteTaker(): void {
      this.$store.commit("showSpinner", true);
      NoteTakerService.addOrUpdateNoteTaker(this.noteTaker)
        .then((success: boolean) => {
          if (success) {
            //TODO: notify user
            console.log("updateNoteTaker():  ~ success:");
          } else {
            console.log("updateNoteTaker():  ~ failure:");
          }
        })
        .finally(() => {
          this.$store.commit("showSpinner", false);
        });
    },
    /**
     * Calls update note-taker to delete the administration note
     */
    deleteAdminNote(): void {
      if (
        this.administrationNotesLength < this.administrationNotesOnLoadLength
      ) {
        this.updateNoteTaker();
      }
    },
  },
  mounted() {
    this.getUsersPagingData();
    this.getNoteTaker();
  },
  watch: {
    $route() {
      this.getNoteTaker();
    },
    administrationNotesLength() {
      this.deleteAdminNote();
    },
  },
})
export default class NoteTakerDetails extends Vue {}
